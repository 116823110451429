import * as tslib_1 from "tslib";
import { GTMEvent } from './gtm-event.model';
import { GTMHelper, GoogleOptimizeService, SettingsService } from '@inflight/core-ng';
import { VirtualPageView } from './gtm-virtual-page-view.model';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
var GTM_USER_ID_KEY = '__gtmUserId__';
var GTMService = /** @class */ (function () {
    function GTMService(router, optimize, title, settings) {
        var _this = this;
        this.optimize = optimize;
        this.title = title;
        this.settings = settings;
        this.isEnabled = false;
        if (sessionStorage[GTM_USER_ID_KEY]) {
            this.userId = sessionStorage[GTM_USER_ID_KEY];
        }
        this.isEnabled = !!this.settings.Properties.get('gtmConfigs').enabled;
        router.events
            .pipe(filter(function (event) {
            return !!(event instanceof NavigationEnd &&
                window.dataLayer &&
                event.urlAfterRedirects);
        }), map(function (event) {
            var url = event.urlAfterRedirects;
            var data = new VirtualPageView();
            data.virtualPageTitle = _this.title.getTitle();
            var urlRegex = /([^\(?]+)[?\(]?.*/;
            var match = urlRegex.exec(event.urlAfterRedirects);
            if (match) {
                url = match[1];
            }
            if (url.lastIndexOf('/') === url.length - 1) {
                url = url.substr(0, url.length - 1);
            }
            return url;
        }), distinctUntilChanged())
            .subscribe(function (url) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                data = new VirtualPageView();
                data.virtualPageURL = url;
                this.push(data);
                return [2 /*return*/];
            });
        }); });
    }
    GTMService.prototype.clear = function () {
        this.userId = undefined;
        delete sessionStorage[GTM_USER_ID_KEY];
    };
    GTMService.prototype.login = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, data;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, GTMHelper.hash(userId)];
                    case 1:
                        _a.userId = _b.sent();
                        sessionStorage[GTM_USER_ID_KEY] = this.userId;
                        return [4 /*yield*/, this.getGTMEvent()];
                    case 2:
                        data = _b.sent();
                        data.eventCategory = 'ibm';
                        data.eventAction = 'login';
                        data.eventLabel = 'completion';
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.dashboardClick = function (tileText) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getButtonClickEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventCategory = 'ibm';
                        data.eventAction = 'links-click';
                        data.eventLabel = tileText;
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.helpClick = function (linkText) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getButtonClickEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventCategory = 'ibm';
                        data.eventAction = 'additional-links';
                        data.eventLabel = linkText;
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.loginClick = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getButtonClickEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventCategory = 'external landing page';
                        data.eventAction = 'button-clicks';
                        data.eventLabel = "login";
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.newsClick = function (articleTitle) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getButtonClickEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventCategory = 'ibm';
                        data.eventAction = 'news-article';
                        data.eventLabel = articleTitle;
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.quickLinkClick = function (linkName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getButtonClickEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventCategory = 'ibm';
                        data.eventAction = 'faculty-staff-links';
                        data.eventLabel = linkName;
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.helpfulLinkClick = function (linkName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getButtonClickEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventCategory = 'external landing page';
                        data.eventAction = 'button-clicks';
                        data.eventLabel = linkName;
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.personalInfoAction = function (componentName, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getButtonClickEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventCategory = 'ibm';
                        data.eventAction = action;
                        data.eventLabel = componentName;
                        this.push(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    GTMService.prototype.getGTMEvent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                data = new GTMEvent();
                return [2 /*return*/, data];
            });
        });
    };
    GTMService.prototype.getButtonClickEvent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getGTMEvent()];
                    case 1:
                        data = _a.sent();
                        data.eventAction = 'button-clicks';
                        return [2 /*return*/, data];
                }
            });
        });
    };
    GTMService.prototype.push = function (data) {
        if (this.isEnabled) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(data);
            this.optimize.triggerOptimize();
        }
    };
    return GTMService;
}());
export { GTMService };
