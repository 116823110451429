import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var EventMediatorService = /** @class */ (function () {
    function EventMediatorService() {
        this.subject$ = new Subject();
    }
    EventMediatorService.prototype.on = function (event, action) {
        return this.subject$
            .pipe(filter(function (e) { return e.name === event; }), map(function (e) { return e.value; }))
            .subscribe(action);
    };
    EventMediatorService.prototype.emit = function (event) {
        this.subject$.next(event);
    };
    EventMediatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventMediatorService_Factory() { return new EventMediatorService(); }, token: EventMediatorService, providedIn: "root" });
    return EventMediatorService;
}());
export { EventMediatorService };
var MediatorEvent = /** @class */ (function () {
    function MediatorEvent(sender, name, value) {
        this.sender = sender;
        this.name = name;
        this.value = value;
    }
    return MediatorEvent;
}());
export { MediatorEvent };
