import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { ErrorComponent } from '@inflight/ui-ng';
var ɵ0 = function () {
    return import("./customer/portal/portal.module.ngfactory").then(function (m) { return m.PortalModuleNgFactory; });
}, ɵ1 = { errorType: 'general' }, ɵ2 = { errorType: 'not-found' }, ɵ3 = { errorType: 'network' }, ɵ4 = { errorType: 'not-found' };
var routes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'error',
        component: ErrorComponent,
        data: ɵ1
    },
    {
        path: 'error/page-not-found',
        component: ErrorComponent,
        data: ɵ2
    },
    {
        path: 'error/network',
        component: ErrorComponent,
        data: ɵ3
    },
    {
        path: '**',
        component: ErrorComponent,
        data: ɵ4
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    AppRoutingModule._shouldReuseRoute = function (route) {
        var next = route;
        while (next) {
            if (next.data && next.data.shouldReuseRoute === false) {
                return false;
            }
            next = next.firstChild;
        }
        return true;
    };
    AppRoutingModule._getComponentName = function (route) {
        var next = route;
        while (next) {
            if (next.component && next.component['name']) {
                return next.component['name'];
            }
            next = next.firstChild;
        }
        return null;
    };
    AppRoutingModule.shouldReuseRoute = function (future, current) {
        if (AppRoutingModule._getComponentName(future) ===
            AppRoutingModule._getComponentName(current)) {
            if (AppRoutingModule._shouldReuseRoute(future) &&
                AppRoutingModule._shouldReuseRoute(current)) {
                return true;
            }
        }
        return false;
    };
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
